.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 32px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
  font-style: italic;
  text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 32px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.footer {
  padding: 4px 16px ;
  font-size: 0.7rem;
}

.row-mb {
  margin-bottom: 12px;
}

.card-graph > .ant-card-body {
  padding: 0 !important
}

.content-panel {
  margin: 0 24px 24px 24px;
}

.ant-row {
  margin-bottom:12px !important
}